<template>
  <div>
    <span v-if="item.openAmount || item.openAmount === 0" class="font-weight-bold mr-1">
      <v-tooltip right max-width="400">
        <template #activator="{ on }">
          <span v-on="on" class="error--text" style="cursor: pointer">
            {{ formatCurrency(item.openAmount) }}
          </span>
        </template>
        <span>
          Avoinna: {{ formatCurrency(item.openAmount) }} <br />
          Avoinna oleva korko: {{ formatCurrency(item.openInterest) }} <br />
          Avoinna olevat kulut: {{ formatCurrency(item.openExpenses) }} <br />
        </span>
      </v-tooltip>
    </span>
    <!-- if not openamount then show totalAmount -->
    <span v-else class="font-weight-bold error--text mr-1">{{
      formatCurrency(item.totalAmount)
    }}</span>
    <span
      v-if="item.paidAmounts.length > 0"
      style="cursor: pointer"
      @click="$emit('openpaidamountdialog')"
      ><v-icon small>mdi-comment-edit-outline</v-icon></span
    >
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
export default {
  mixins: [mixins],

  props: {
    item: Object,
  },
};
</script>

<style></style>
