<template>
  <!-- Dialog Adding key -->

  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title> Lisää avain </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-checkbox v-model="relatedToContract" label="Hae vapaa avain vuokrasopimusten kautta">
          </v-checkbox>

          <v-autocomplete
            v-if="relatedToContract"
            v-model="tenantContracts"
            :items="contracts"
            :item-text="nameContractnumber"
            item-value="_id"
            label="Vuokrasopimus"
            multiple
            outlined
            small-chips
            :search-input.sync="searchInput1"
            @change="searchInput1 = ''"
          >
            <template slot="item" slot-scope="{ item }">
              {{ item.contractNumber }} ({{ item.tenant.name }})
            </template></v-autocomplete
          >

          <v-autocomplete
            v-if="(relatedToContract && tenantContracts.length > 0) || !relatedToContract"
            v-model="tenantKeys"
            :items="keys"
            item-text="combinedName"
            item-value="_id"
            label="Vapaat avaimet"
            multiple
            outlined
            small-chips
            :search-input.sync="searchInput2"
            @change="searchInput2 = ''"
            @focus="getAvailableKeys()"
          ></v-autocomplete>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="addTenantToKey()">Lisää avain</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosMethods from "@/mixins/axios";
import mixins from "@/mixins/mixins";
import { mapActions } from "vuex";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      searchInput1: "",
      searchInput2: "",
      relatedToContract: false,
      contracts: [],
      keys: [],
      tenantContracts: [],
      tenantKeys: [],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    relatedToContract() {
      if (!this.relatedToContract) this.getAvailableKeys();
    },

    "tenant.contracts": {
      deep: true,
      handler() {
        this.getAvailableKeys();
      },
    },
  },

  async created() {
    await this.getContracts();
  },

  methods: {
    ...mapActions("tenant", ["addTenantKeys"]),

    async addTenantToKey() {
      if (this.tenantKeys.length < 1) {
        return this.showPopup("Valitse ainakin yksi avain", "error");
      }

      this.addTenantKeys({
        url: "/api/v1/key/addTenantToKeys/" + this.$route.params.id,
        keys: this.tenantKeys,
      });

      this.tenantKeys = [];
    },

    async getContracts() {
      try {
        let res = await axiosMethods.get("/api/v1/rental-contract/getAll?state=Voimassa oleva");
        this.contracts = res.data.rentalContracts;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async getAvailableKeys() {
      try {
        let res;
        if (this.relatedToContract) {
          res = await axiosMethods.post("/api/v1/key/getAvailableKeys/", {
            contracts: this.tenantContracts,
          });
        } else {
          res = await axiosMethods.post("/api/v1/key/getAvailableKeys/");
        }
        this.keys = this.formatKeys(res.data.keys);
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    formatKeys(keys) {
      return keys.map((el) => {
        let rights = "";

        el.apartments.forEach((item, index) => {
          if (index < el.apartments.length - 1) {
            rights += item.apartmentNumber
              ? `${item.address} (${item.apartmentNumber}), `
              : `${item.address}, `;
          } else {
            rights += item.apartmentNumber
              ? `${item.address} (${item.apartmentNumber})`
              : `${item.address}`;
          }
        });
        if (rights) {
          el.combinedName = `${el.code} | ${rights}`;
        } else {
          el.combinedName = el.code;
        }
        return el;
      });
    },

    nameContractnumber(item) {
      if (item.tenant) {
        return item.contractNumber + " " + item.tenant.name;
      }
    },
  },
};
</script>

<style scoped></style>
