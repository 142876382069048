<template>
  <v-container>
    <v-card flat>
      <v-card-title>Maksuhäiriöraportti</v-card-title>
      <v-card-subtitle
        >Yhteenveto kaikista avoimista laskuista, jotka ovat erääntyneet tai joihin on kirjattu
        luottotappioita.</v-card-subtitle
      >
      <v-card-text>
        <v-row dense>
          <v-col md="4">
            <v-btn color="info" @click="getTenantInvoiceReport">Hae maksuhäiriöraportti</v-btn>
            <br />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import { apiAgent } from "../../services/apiAgent";
import { mapState } from "vuex";
import moment from "moment";

export default {
  title: "Raportit",

  mixins: [mixins],

  computed: {
    ...mapState("tenant", ["currentTenant"]),
  },

  methods: {
    async getTenantInvoiceReport() {
      try {
        const res = await apiAgent({
          method: "get",
          url: `/api/v1/tenant/download-tenant-invoice-report/${this.$route.params.id}`,
        });

        if (res.data.invoices.length == 0) {
          return this.showPopup("Laskuja ei löytynyt", "error");
        }

        const idOfTenant =
          this.currentTenant.isCompany && this.currentTenant.businessId
            ? this.currentTenant.businessId
            : this.currentTenant.social
            ? this.currentTenant.social.slice(0, 6)
            : "";

        const additionalInfo = [
          `Maksuhäiriöraportti`,
          `Vuokralainen: ${this.currentTenant.name}`,
          `Hetu/Y-tunnus: ${idOfTenant}`,
          `Raportin päivämäärä: ${moment().format("DD.MM.YYYY")}`,
        ];

        const sumColumns = [
          "Alkuperäiset saatavat ilman alv",
          "Alv 25,5 %",
          "Alv 24 %",
          "Alv 10 %",
          "Alkuperäiset saatavat sis. alv",
          "Korot yhteensä",
          "Saatavat yhteensä",
          "Suoritukset",
          "Hyvitykset",
          "Avoinna",
          "Luottotappiot",
        ];

        const buffer = await this.createExcelBuffer(
          res.data.invoices,
          additionalInfo,
          true,
          true,
          true,
          sumColumns
        );
        this.downloadExcel(buffer, "Maksuhäiriöraportti");
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
  },
};
</script>

<style></style>
