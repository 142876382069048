<template>
  <div class="py-3 min-w-0 w-full">
    <!-- <div class="display-1">Muut sopimukset</div> -->

    <v-card flat>
      <!-- <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Hae kaikista kentistä..."
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-container> -->

      <v-data-table
        :headers="headers"
        :items="contracts"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- Contract number -->
        <template #[`item.contractNumber`]="{ item }">
          <span class="font-weight-bold"># {{ item.contractNumber }} </span>
        </template>

        <!-- OtherTenants -->
        <template #[`item.otherTenants`]="{ item }">
          <div v-for="(tenant, index) in item.otherTenants" :key="'a' + index">
            {{ tenant.name }}
          </div>
        </template>

        <!-- Landlords -->
        <template #[`item.landlords`]="{ item }">
          <div v-for="(landlord, index) in item.landlords" :key="'a' + index">
            {{ landlord.landlordId.name }}
          </div>
        </template>

        <!-- Apartment -->
        <template #[`item.apartment`]="{ item }">
          <span v-if="item.apartment">
            {{ item.apartment.address
            }}<span v-if="item.apartment.apartmentNumber"
              >, {{ item.apartment.apartmentNumber }}</span
            >
          </span>
        </template>

        <!-- Keys -->
        <template #[`item.keys`]="{ item }">
          <span v-for="(key, index) in item.keys" :key="'a' + index">
            <span class="font-weight-bold success--text">{{ key.code }}</span
            ><span v-if="index < item.keys.length - 1"> | </span>
          </span>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container" v-if="!item.itemLoading">
            <v-icon
              v-if="isAuthorized('contract', 'create')"
              :color="getSignatureColor(item)"
              title="Allekirjoitus"
              small
              @click="
                showSelectSignatureDialog = true;
                setToSignatureState(item);
              "
              >mdi-draw</v-icon
            >
            <v-icon
              v-if="isAuthorized('contract', 'read')"
              title="Lataa sopimus"
              small
              @click="
                showDownloadLoader = true;
                downloadPdf(item);
              "
              >mdi-file-pdf</v-icon
            >
            <v-icon
              v-if="item.signature.status != 'completed' && isAuthorized('contract', 'edit')"
              title="Muokkaa"
              small
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="item.signature.status != 'completed' && isAuthorized('contract', 'delete')"
              title="Poista"
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </div>
          <v-row v-if="item.itemLoading">
            <v-col>
              <small>Ladataan sopimusta...</small>
              <v-progress-linear color="info" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
          </v-row>
        </template>
        <template #no-data>
          <h2>Ei sopimuksia</h2>
        </template>
      </v-data-table>
    </v-card>

    <!-- Select signature -->
    <select-signature v-model="showSelectSignatureDialog"></select-signature>

    <!-- Download loader -->
    <download-loader
      v-model="showDownloadLoader"
      :loading="downloading"
      text="Odota, ladataan sopimusta..."
    ></download-loader>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import { apiAgent } from "../../services/apiAgent";
import mixins from "../../mixins/mixins";
import SelectSignature from "../../components/Signature/SelectSignature";
import DownloadLoader from "../../components/DownloadLoader.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";

export default {
  title: "Muut sopimukset",

  components: {
    SelectSignature,
    DownloadLoader,
  },

  mixins: [mixins],

  data: () => ({
    downloading: false,
    showDownloadLoader: false,
    loading: true,
    options: {},
    dialog: false,
    search: "",
    showSelectSignatureDialog: false,
    headers: [
      { text: "Nro.", value: "contractNumber" },
      { text: "Nimi", value: "name" },
      {
        text: "Muut vuokralaiset",
        value: "otherTenants",
        sortable: false,
      },
      {
        text: "Vuokrantaja",
        value: "landlords",
        sortable: false,
      },
      {
        text: "Vuokrakohde",
        value: "apartment",
        sortable: false,
      },
      {
        text: "Avaimet",
        value: "keys",
        sortable: false,
      },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("tenant", ["currentTenant"]),
    ...mapState("othercontract", ["contracts", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },

    currentTenant() {
      this.getDataFromApi();
    },
  },

  methods: {
    ...mapActions("othercontract", ["getContracts", "deleteContract"]),
    ...mapMutations("othercontract", ["replaceOtherContract"]),
    ...mapMutations("signature", ["setSignatureItem", "setContractType"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("pdfViewer", ["openViewer"]),

    async getDataFromApi() {
      if (this.currentTenant._id) {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        // Save main search term
        this.saveMainSearchTerm(this.search);

        let search = this.search.trim().toLowerCase();

        let url = `/api/v1/other-contract/get-tenant-contracts/${this.currentTenant._id}?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

        await this.getContracts(url);

        this.loading = false;
      }
    },

    setToSignatureState(item) {
      this.setSignatureItem(item);
      this.setContractType("otherContract");
    },

    async downloadPdf(item) {
      try {
        this.downloading = true;

        const res = await apiAgent({
          method: "GET",
          url: `/api/v1/other-contract/download-pdf/${item._id}`,
          responseType: "arraybuffer",
        });

        const blob = new Blob([res.data], { type: "application/pdf" });
        this.downloading = false;

        this.openViewer({
          data: blob,
          name: `${item.name}_${item.contractNumber}.pdf`,
        });
      } catch (err) {
        this.downloading = false;
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    editItem(item) {
      this.$router.push("/other-contract/edit/" + item._id);
    },

    deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa sopimuksen: " + item.name))
        this.deleteContract(item._id);
    },
  },
};
</script>

<style scoped></style>
