<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-container>
        <v-card-title>
          {{ edit ? "Muokkaa muistiinpanoa" : "Luo muistiinpano" }}
          <v-icon color="primary" class="ml-1"> mdi-comment</v-icon>
        </v-card-title>

        <v-card-text class="mt-1">
          <v-form ref="form">
            <v-textarea
              v-model="currentNote.text"
              rows="5"
              outlined
              dense
              label="Tekstiä..."
              :rules="validations.required"
            ></v-textarea>

            <v-autocomplete
              v-if="model == 'tenant'"
              v-model="currentNote.contract"
              :item-text="contractNumberAddress"
              item-value="_id"
              :items="tenantContractList"
              label="Liittyy sopimukseen"
              outlined
              dense
              hide-details
              clearable
            ></v-autocomplete>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="submit">{{ edit ? "Tallenna" : "Luo" }}</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import validations from "../../validations";
export default {
  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    model: String,
  },

  data() {
    return {
      validations,
    };
  },

  computed: {
    ...mapState("note", ["currentNote"]),
    ...mapState("contract", ["tenantContractList"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(val) {
      if (val && !this.edit) {
        this.$nextTick(() => {
          this.setCurrentNote({});
          this.$refs.form.resetValidation();
        });
      }
    },
  },

  methods: {
    ...mapMutations("note", ["setCurrentNote"]),

    contractNumberAddress(i) {
      const contractNumber = i.contractNumber;
      const address = i.apartment.id.address;
      const apartmentNumber = i.apartment.id.apartmentNumber || "";
      return apartmentNumber
        ? `Sop.nro ${contractNumber} | ${address}, ${apartmentNumber}`
        : `Sop.nro ${contractNumber} | ${address}`;
    },

    submit() {
      if (this.$refs.form.validate()) {
        if (this.edit) {
          this.$emit("editnote");
        } else {
          this.$emit("createnote");
        }
      }
    },
  },
};
</script>

<style></style>
