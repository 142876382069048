<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title> Poista avain {{ key.code }} henkilöltä </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <h3 class="mb-2">Mihin tilaan avain asetetetaan poistamisen jälkeen?</h3>
          <v-select v-model="status" :items="items" outlined dense :rules="required" hide-details>
          </v-select>
          <v-checkbox
            v-model="deletePermissions"
            label="Poista kaikki avaimen kulkuoikeudet"
          ></v-checkbox>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="removeKey"> Poista avain</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      items: ["Vapaa", "Poistunut"],
      status: "",
      deletePermissions: false,
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("keys", ["key"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapActions("tenant", ["removeKeyFromTenant"]),

    removeKey() {
      if (this.$refs.form.validate()) {
        this.removeKeyFromTenant({
          key: this.key,
          status: this.status,
          deletePermissions: this.deletePermissions,
          tenantId: this.$route.params.id,
        });
        this.dialog = false;
      }
    },
  },
};
</script>

<style scoped>
.heading3 {
  font-size: 14px !important;
}
</style>
