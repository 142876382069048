<template>
  <div class="py-3 min-w-0 w-full">
    <v-card flat>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="showInactiveContracts"
              dense
              label="Näytä vain irtisanotut ja päättyneet sopimukset"
              @change="showInactiveContracts ? (search = '') : ''"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>

      <!-- TABLE -->
      <v-data-table
        :headers="headers"
        :items="rentalContracts"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!--  Landlord -->
        <template #[`item.landlords`]="{ item }">
          <div v-for="(landlord, idx) in item.landlords" :key="'a' + idx">
            <router-link
              v-if="landlord.onModel == 'Partner'"
              style="text-decoration: none"
              :to="`/partner/overview/${landlord._id}`"
              >{{ landlord.name }}</router-link
            >
            <span v-else>{{ landlord.name }}</span>
          </div>

          <div>
            <v-icon
              v-if="hasContractEmails(item, 'partner')"
              small
              @click="sendMail(item, 'partner')"
              >mdi-email-send-outline</v-icon
            >
          </div>
        </template>

        <!--  Tenants -->
        <template #[`item.tenant`]="{ item }">
          <router-link
            v-if="item.tenant.tenantId._id != $route.params.id"
            style="text-decoration: none"
            :to="`/tenant/overview/${item.tenant.tenantId._id}`"
          >
            <span :class="{ 'warning--text': item.tenant.revokedDate }">{{
              item.tenant.tenantId.name
            }}</span>
          </router-link>

          <span v-else :class="{ 'warning--text': item.tenant.revokedDate }">{{
            item.tenant.tenantId.name
          }}</span>

          <div v-for="(tenant, idx) in item.otherTenants" :key="'a' + idx">
            <router-link
              v-if="tenant.tenantId._id != $route.params.id"
              style="text-decoration: none"
              :to="`/tenant/overview/${tenant.tenantId._id}`"
            >
              <span :class="{ 'warning--text': tenant.revokedDate }">{{
                tenant.tenantId.name
              }}</span>
            </router-link>
            <span v-else :class="{ 'warning--text': tenant.revokedDate }">{{
              tenant.tenantId.name
            }}</span>
          </div>

          <div>
            <v-icon v-if="hasContractEmails(item, 'tenant')" small @click="sendMail(item, 'tenant')"
              >mdi-email-send-outline</v-icon
            >
          </div>
        </template>

        <!--  Apartment -->
        <template #[`item.apartment`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="`/apartment/overview/${item.apartment.id._id}`"
          >
            <span>{{ item.apartment.id.address }}</span>
            <span v-if="item.apartment.id.apartmentNumber"
              >, {{ item.apartment.id.apartmentNumber }}
            </span>
          </router-link>

          <div v-for="(room, idx) in getRentedRooms(item.apartment)" :key="idx + 'a'">
            <p class="draft--text" style="margin-bottom: -2px !important">{{ room.name }}</p>
          </div>
        </template>

        <!--  StartDate -->
        <template #[`item.lease.startDate`]="{ item }">
          <span>{{ formatDate(item.lease.startDate) }}</span>
        </template>

        <!--  EndDate -->
        <template #[`item.lease.endDate`]="{ item }">
          <span>{{ formatDate(item.lease.endDate) }}</span>
        </template>

        <!--  RevokedDate -->
        <template #[`item.revokedDate`]="{ item }">
          <span>{{ formatDate(item.revokedDate) }}</span>
        </template>

        <!--  Overview link -->
        <template #[`item.contractNumber`]="{ item }">
          <router-link style="text-decoration: none" :to="`/rental-contract/overview/${item._id}`">
            <span class="font-weight-bold">
              {{ item.contractNumber }}
            </span>
          </router-link>
        </template>

        <!-- BaseInvoice -->
        <template #[`item.baseInvoice`]="{ item }">
          <div v-html="formatBaseInvoice(item.baseInvoice)"></div>
        </template>

        <!-- Item state -->
        <template #[`item.state`]="{ item }">
          <div class="font-weight-bold d-flex align-center text-no-wrap">
            <div :class="getContractStateColor(item.state)">
              <span>
                {{ getContractState(item.state) }}
              </span>
            </div>
          </div>
        </template>

        <!-- Actions -->

        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container" v-if="!item.itemLoading">
            <!-- <v-icon
              v-if="isAuthorized('contract', 'create') && !item.revoked && !item.signed"
              title="Aktivoi"
              small
              @click="markSigned(item)"
              >mdi-check</v-icon
            > -->

            <v-icon
              v-if="
                isAuthorized('contract', 'create') && item.signed && !item.revoked && item.active
              "
              title="Irtisano vuokrasopimus"
              small
              @click.stop="
                refreshRevoked = false;
                refreshType = 'newRevokedDate';
                revokeDialog = true;
                selectedItem = { ...item };
              "
              >mdi-stop-circle-outline</v-icon
            >

            <v-icon
              v-if="
                isAuthorized('contract', 'edit') &&
                item.signed &&
                item.contractType != 'reservation' &&
                (item.state == 'Irtisanottu' ||
                  (item.state == 'Päättynyt' &&
                    item.lease.leaseType == 'Toistaiseksi voimassa oleva'))
              "
              title="Muuta irtisanomispäivää"
              small
              @click.stop="
                refreshRevoked = true;
                revokeDialog = true;
                selectedItem = { ...item };
              "
              >mdi-refresh</v-icon
            >

            <v-icon
              v-if="isAuthorized('contract', 'create') && item.contractType == 'new'"
              :color="getSignatureColor(item)"
              title="Allekirjoitus"
              small
              @click="
                showSelectSignatureDialog = true;
                setToSignatureState(item);
              "
              >mdi-draw</v-icon
            >

            <!-- Edit contract -->
            <v-icon
              v-if="isAuthorized('contract', 'edit') && item.contractType == 'new' && !item.signed"
              title="Muokkaa"
              small
              @click="$router.push(`/rental-contract/edit/` + item._id).catch(() => {})"
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="
                isAuthorized('contract', 'edit') &&
                item.contractType == 'old' &&
                item.state != 'Päättynyt' &&
                item.state != 'Irtisanottu'
              "
              title="Muokkaa"
              small
              @click="$router.push(`/rental-contract/edit-old/` + item._id).catch(() => {})"
              >mdi-pencil</v-icon
            >

            <v-icon
              v-if="isAuthorized('contract', 'read') && item.contractType == 'new'"
              title="Lataa vuokrasopimus"
              small
              @click="
                showDownloadLoader = true;
                downloadPdf(item);
              "
              >mdi-file-pdf</v-icon
            >

            <v-icon
              v-if="
                isAuthorized('contract', 'create') &&
                item.signed &&
                item.contractType != 'reservation'
              "
              title="Luo lasku"
              small
              @click="$router.push(`/invoice/create/${item._id}`)"
              >mdi-currency-eur</v-icon
            >

            <v-icon
              v-if="
                isAuthorized('contract', 'create') && item.depositPaid.paid && !item.depositReleased
              "
              title="Merkitse vakuus vapautetuksi"
              small
              @click="releaseDepositOLD(item)"
              >mdi-lock-open</v-icon
            >

            <v-icon
              v-if="isAuthorized('contract', 'create')"
              :class="{ 'info--text': hasItems(item.files) }"
              v-model="showFileDialog"
              title="Liitä dokumentteja"
              small
              @click="
                contractId = item._id;
                showFileDialog = true;
              "
              >mdi-paperclip</v-icon
            >

            <v-icon
              v-if="isAuthorized('contract', 'delete') && !item.signed"
              title="Poista"
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </div>
          <v-row v-if="item.itemLoading">
            <v-col>
              <small>Ladataan sopimusta...</small>
              <v-progress-linear color="info" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
          </v-row>
        </template>

        <template #no-data>
          <h2>Ei vuokrasopimuksia</h2>
        </template>
      </v-data-table>

      <!--  Revoke contract -->
      <revoke-contract-dialog
        v-model="revokeDialog"
        :selectedItem="selectedItem"
        :refreshRevoked="refreshRevoked"
        :refreshType="refreshType"
      ></revoke-contract-dialog>
    </v-card>

    <!-- showDepositDialog -->
    <deposit-dialog
      v-model="showDepositDialog"
      :contractNum="selectedContract.contractNumber"
      :depositsArr="selectedContract.deposits"
      :contractId="selectedContract._id"
      @adddeposit="addDeposit($event)"
      @deletedeposit="deleteDeposit($event)"
    >
    </deposit-dialog>

    <!-- Select signature -->
    <select-signature v-model="showSelectSignatureDialog"></select-signature>

    <!-- File dialog -->
    <File
      v-model="showFileDialog"
      :document-id="contractId"
      title="Lataa vuokrasopimuksen liite"
      bucket="vuokranet-sopimukset"
      document="contract"
      model="rental-contract"
      @sendnewfiles="replaceFilesArray"
    ></File>

    <!-- Download loader -->
    <download-loader
      v-model="showDownloadLoader"
      :loading="downloading"
      text="Odota, ladataan vuokrasopimusta..."
    ></download-loader>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import { apiAgent } from "../../services/apiAgent";
import mixins from "../../mixins/mixins";
import DepositDialog from "../../components/RentalContract/DepositDialog";
import SelectSignature from "../../components/Signature/SelectSignature";
import RevokeContractDialog from "../../components/RentalContract/RevokeContractDialog.vue";
import File from "../../components/File";
import DownloadLoader from "../../components/DownloadLoader.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";

export default {
  title: "Vuokrasopimukset",

  components: {
    DepositDialog,
    File,
    SelectSignature,
    DownloadLoader,
    RevokeContractDialog,
  },

  mixins: [mixins],

  data: () => ({
    options: {},
    dialog: false,
    search: "",
    refreshType: "newRevokedDate",
    showDownloadLoader: false,
    downloading: false,
    loading: true,
    itemLoading: false,
    searching: false,
    showAddAdditionalTenants: false,
    showInactiveContracts: false,
    showDepositDialog: false,
    showFileDialog: false,
    showSelectSignatureDialog: false,
    selectedContract: {},
    menu: false,
    dateFormatted: "",
    date: "",
    revokeDialog: false,
    refreshRevoked: false,
    selectedItem: {},
    contractId: "",
    user: {},
    deposit: {},
    allHeaders: [
      { text: "Nro.", value: "contractNumber", show: [1, 2] },
      { text: "Vuokralainen", value: "tenant", show: [1, 2] },
      { text: "Vuokranantaja", value: "landlords", show: [1, 2] },
      { text: "Kohde", value: "apartment", show: [1, 2] },
      {
        text: "Tyyppi",
        value: "lease.leaseType",
        show: [1, 2],
      },
      { text: "Alkaa", value: "lease.startDate", show: [1, 2] },
      { text: "Päättyy", value: "lease.endDate", show: [1, 2] },
      { text: "Toistuvaislasku", value: "baseInvoice", show: [1, 2] },
      { text: "Tila", value: "state", show: [1, 2] },
      { text: "Päättymispäivä", value: "revokedDate", show: [2] },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
        show: [1, 2],
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("contract", ["totalLength", "rentalContracts"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    headers() {
      return this.allHeaders.filter((el) => {
        if (this.searching) {
          return el;
        } else if (this.showInactiveContracts) {
          return el.show.includes(2);
        } else {
          return el.show.includes(1);
        }
      });
    },
  },

  watch: {
    showInactiveContracts() {
      this.getDataFromApi();
    },

    "$route.params.id": {
      handler: function () {
        this.getDataFromApi();
      },
      deep: true,
      immediate: true,
    },

    options: {
      deep: true,
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
    },

    totalLength() {
      this.options.page = 1;
    },

    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  methods: {
    ...mapActions("contract", ["getTenantRentalContracts", "deleteContract"]),
    ...mapMutations("contract", ["replaceContract"]),
    ...mapMutations("signature", ["setSignatureItem", "setContractType"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("pdfViewer", ["openViewer"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      //   // Save main search term
      //   this.saveMainSearchTerm(this.search);

      //   const search = this.search.trim().toLowerCase();

      //   // set searching to true or false
      //   this.searching = search || search != "" ? true : false;

      //   let url;

      //   if (search) {
      //     url = `/api/v1/rental-contract/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      //   } else {
      //     url = !this.showInactiveContracts
      //       ? `/api/v1/rental-contract/getAll?state=all&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`
      //       : `/api/v1/rental-contract/getAll?state=old&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      //   }

      const url = !this.showInactiveContracts
        ? `/api/v1/rental-contract/get-tenant-contracts/${this.$route.params.id}?state=all&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}`
        : `/api/v1/rental-contract/get-tenant-contracts/${this.$route.params.id}?state=old&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}`;
      await this.getTenantRentalContracts(url);

      this.loading = false;
    },

    getLandlords(item) {
      let string = "";

      item.landlords.forEach((el) => {
        string += `<span>${el.name}</span> <br>`;
      });
      return string;
    },

    formatBaseInvoice(invoice) {
      if (invoice && invoice.invoiceNumber) {
        return `<span class="font-weight-bold success--text">${invoice.invoiceNumber}</span>`;
      } else if (invoice && !invoice.invoiceNumber) {
        return `<span class="font-weight-bold error--text">Luotu, ei lähetetty</span>`;
      } else {
        return "";
      }
    },

    replaceFilesArray(item) {
      const index = this.rentalContracts.findIndex((obj) => obj._id === item._id);
      if (index !== -1) {
        this.rentalContracts[index].files = item.files;
      }
    },

    async markSigned(item) {
      if (
        confirm(
          `Haluatko aktivoida vuokralaisen ${item.tenant.name} sopimuksen nro. ${item.contractNumber}? Aktivoinnin jälkeen et enää voi muokata sopimusta.`
        )
      ) {
        try {
          await axiosMethods.update("/api/v1/rental-contract/sign-contract/" + item._id);
          this.$router.go();
          this.showPopup("Sopimus allekirjoitettu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    setToSignatureState(item) {
      this.setSignatureItem(item);
      this.setContractType("rentalContract");
    },

    editItem(item) {
      this.$router.push("/rental-contract/edit/" + item._id);
    },

    async addDeposit(deposit) {
      try {
        const res = await axiosMethods.update(
          "/api/v1/rental-contract/receiveDeposit/" + this.selectedContract._id,
          deposit
        );

        this.selectedContract.deposits = res.data.contract.deposits;

        this.showPopup("Vakuus lisätty", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async deleteDeposit(item) {
      try {
        const res = await axiosMethods.update(
          "/api/v1/rental-contract/deleteDeposit/" + this.selectedContract._id,
          item
        );

        this.selectedContract.deposits = res.data.contract.deposits;

        this.showPopup("Vakuus poistettu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    // Deprecated!!!
    async releaseDepositOLD(item) {
      try {
        if (
          confirm(`Asetetaanko vakuus palautetuksi vuokrasopimukselle nro. ${item.contractNumber}?`)
        ) {
          await axiosMethods.update("/api/v1/rental-contract/releaseDepositOLD/" + item._id);

          this.showPopup("Vakuus merkattu palautetuksi", "success");
          this.$router.go();
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async deleteItem(item) {
      if (
        confirm("Haluatko varmasti poistaa vuokralaisen " + item.tenant.name + " vuokrasopimuksen?")
      ) {
        this.deleteContract(item._id);
      }
    },

    async downloadPdf(item) {
      try {
        this.downloading = true;

        const res = await apiAgent({
          method: "GET",
          url: `/api/v1/rental-contract/downloadPdf/${item._id}`,
          responseType: "arraybuffer",
        });

        const blob = new Blob([res.data], { type: "application/pdf" });

        this.downloading = false;

        this.openViewer({
          data: blob,
          name: `Vuokrasopimus_${item.contractNumber}.pdf`,
        });
      } catch (err) {
        this.downloading = false;
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
  },
};
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
