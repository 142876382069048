<template>
  <div>
    <h3 class="mb-2">Muistiinpanot</h3>

    <p v-if="notes.length == 0">Ei vielä luotuja muistiinpanoja...</p>

    <!--  All notes -->
    <div v-for="(note, idx) in notes.slice().reverse()" :key="'a' + `${idx}`">
      <!-- Dates -->
      <div class="dates-container mb-2">
        <div>
          <small>Luotu: {{ formatDate(note.createdAt) }}</small>
        </div>

        <div v-if="note.contract">
          <small style="font-size: 12px" class="font-weight-bold primary--text"
            >Liittyy sopimukseen: {{ note.contract.contractNumber }} |
            {{ note.contract.apartment.id.address
            }}<span v-if="note.contract.apartment.id.apartmentNumber"
              >, {{ note.contract.apartment.id.apartmentNumber }}</span
            ></small
          >
        </div>
      </div>

      <!-- Text -->
      <p style="white-space: pre-line; font-size: 1em; line-height: 1.3">
        {{ note.text }}
        <span>
          <v-btn v-if="isAuthorized('note', 'edit')" small icon>
            <v-icon small @click="editNote(note)">mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon small>
            <v-icon
              v-if="isAuthorized('note', 'delete')"
              color="error"
              small
              @click="deleteNote(note._id)"
              >mdi-delete</v-icon
            >
          </v-btn>
        </span>
      </p>

      <!-- Creator -->
      <div>
        <small>{{ note.createdByUser.name }}</small>
      </div>
      <div class="dates-container">
        <small v-if="note.updatedAt">Muokattu: {{ formatDate(note.updatedAt) }}</small>
      </div>

      <v-divider class="my-2"></v-divider>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";

export default {
  mixins: [mixins],

  data() {
    return {
      formDialog: false,
    };
  },

  computed: {
    ...mapState("note", ["notes"]),
  },

  methods: {
    editNote(note) {
      this.$emit("openforedit", note);
    },

    deleteNote(id) {
      if (confirm("Haluatko varmasti poistaa muistiinpanon?")) {
        this.$emit("deletenote", id);
      }
    },
  },
};
</script>

<style scoped>
.dates-container {
  display: flex;
  flex-direction: column;
  line-height: 1.1 !important;
  font-size: 0.95em !important;
}
</style>
