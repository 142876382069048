<template>
  <note-template model="tenant"></note-template>
</template>

<script>
import NoteTemplate from "../Note/NoteTemplate.vue";
import { mapActions } from "vuex";

export default {
  title: "Muistiinpanot",

  components: {
    NoteTemplate,
  },

  created() {
    this.getTenantRentalContractList(this.$route.params.id);
  },

  methods: {
    ...mapActions("contract", ["getTenantRentalContractList"]),
  },
};
</script>

<style></style>
