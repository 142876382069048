<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title> Lataa lasku nro. {{ invoice.invoiceNumber }} </v-card-title>
      <v-card-subtitle
        v-if="showBasedOnService({ ropoCapital: true, talenom: false }, invoice.service)"
        >Muistutus tai perintätilassa olevista laskuista ei voi ladata päivittynyttä
        laskua.</v-card-subtitle
      >

      <v-card-text>
        <v-select
          v-model="downloadStatusCode"
          :items="items"
          item-text="text"
          item-value="val"
          outlined
          dense
          label="Valitse laskun tulostustapa"
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="$emit('downloadpdf', downloadStatusCode)">Lataa</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import invoiceMixins from "../../mixins/invoiceMixins";

export default {
  mixins: [invoiceMixins],

  props: {
    value: { type: Boolean, default: false },
    invoice: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      downloadStatusCode: 0,
      items: [
        { text: "Päivittynyt lasku", val: 0 },
        { text: "Alkuperäinen lasku", val: 1 },
      ],
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
