<template>
  <div>
    <div class="text-right">
      <v-btn
        v-if="isAuthorized('key', 'create')"
        color="info"
        class="mb-2 mr-2"
        @click="addKeyForm = true"
        >Lisää uusi avain</v-btn
      >

      <router-link
        v-if="isAuthorized('otherContract', 'create')"
        :to="`/other-contract/create-document?tenant=${$route.params.id}`"
      >
        <v-btn color="info" class="mb-2">Uusi avainluovutussopimus</v-btn>
      </router-link>
    </div>
    <div v-if="currentTenant.keys.length < 1" class="text-center mb-5">
      <h3>Ei liitettyjä avaimia</h3>
    </div>
    <v-row dense>
      <v-col
        v-for="(key, idx) in currentTenant.keys"
        :key="idx + 'a'"
        cols="12"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <v-card height="100%" class="d-flex flex-column">
          <div>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline">
                  <span v-if="key.manualSignature" class="success--text font-weight-bold"
                    >Kuitattu</span
                  ><span v-else class="error--text font-weight-bold">Ei kuitattu</span>
                </div>
                <span class="overline mb-2 error--text" v-if="key.status == 'Poistunut'"
                  >Poistunut</span
                >
                <span class="overline mb-2 warning--text" v-if="key.status == 'Käytössä'"
                  >Käytössä</span
                >
                <v-list-item-title class="headline mb-2">
                  {{ key.code }}
                </v-list-item-title>

                <!-- condominiums -->
                <p v-if="key.condominiums.length < 1" class="error--text">Ei taloyhtiöoikeuksia</p>

                <div v-for="(cond, idx) in key.condominiums" :key="idx + 'b'">
                  <div style="margin-bottom: 5px">{{ cond.name }}</div>

                  <p
                    v-for="(apart, idx) in getMatchingApartments(cond, key.apartments)"
                    :key="idx + 'c'"
                    style="margin-bottom: -1px"
                  >
                    <span v-if="cond._id == apart.condominium._id">
                      <span>{{ apart.address }}</span
                      ><span>{{ apart.apartmentNumber ? `, ${apart.apartmentNumber}` : "" }}</span>
                    </span>
                  </p>

                  <v-divider
                    v-if="idx < key.condominiums.length - 1"
                    style="margin-top: 10px; margin-bottom: 7px"
                  ></v-divider>
                </div>

                <p v-if="key.apartments.length < 1" class="error--text" style="margin-top: 10px">
                  Ei yksittäisiä vuokrakohdeoikeuksia
                </p>
                <!-- apartments -->
              </v-list-item-content>
              <v-list-item-avatar size="35">
                <v-icon medium>mdi-key-variant</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </div>

          <v-card-actions class="mt-auto">
            <v-btn
              outlined
              small
              color="error"
              @click="
                $store.state.keys.key = { ...key };
                removeKeyForm = true;
              "
            >
              Poista
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              small
              color="info"
              @click="
                $store.state.keys.key = { ...key };
                editSingleDialog = true;
              "
            >
              Muokkaa
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Add tenant Key -->
    <add-tenant-key v-model="addKeyForm"></add-tenant-key>

    <!-- Remove tenant key -->
    <remove-tenant-key v-model="removeKeyForm"></remove-tenant-key>

    <!--  EDIT SINGLE KEY -->
    <edit-single-dialog v-model="editSingleDialog"></edit-single-dialog>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import AddTenantKey from "./AddTenantKey";
import RemoveTenantKey from "./RemoveTenantKey";
import EditSingleDialog from "../Keys/EditSingleDialog";
import { mapState } from "vuex";

export default {
  title: "Hallitse vuokralaisen avaimia",

  components: {
    AddTenantKey,
    RemoveTenantKey,
    EditSingleDialog,
  },

  mixins: [mixins],

  data: () => ({
    addKeyForm: false,
    removeKeyForm: false,
    editSingleDialog: false,
  }),

  computed: {
    ...mapState("tenant", ["currentTenant"]),
  },

  methods: {
    getMatchingApartments(condominium, apartments) {
      return apartments.filter((apartment) => apartment.condominium._id == condominium._id);
    },
  },
};
</script>

<style scoped>
.v-list-item__title {
  font-weight: 600;
  font-size: 18px !important;
}

p {
  color: rgb(139, 139, 139);
  font-size: 13px !important;
}

@media (max-width: 768px) {
  p {
    font-size: 11px !important;
  }
}
</style>
