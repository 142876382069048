var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contracts,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.contractNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(item.contractNumber)+" ")])]}},{key:"item.otherTenants",fn:function(ref){
var item = ref.item;
return _vm._l((item.otherTenants),function(tenant,index){return _c('div',{key:'a' + index},[_vm._v(" "+_vm._s(tenant.name)+" ")])})}},{key:"item.landlords",fn:function(ref){
var item = ref.item;
return _vm._l((item.landlords),function(landlord,index){return _c('div',{key:'a' + index},[_vm._v(" "+_vm._s(landlord.landlordId.name)+" ")])})}},{key:"item.apartment",fn:function(ref){
var item = ref.item;
return [(item.apartment)?_c('span',[_vm._v(" "+_vm._s(item.apartment.address)),(item.apartment.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(item.apartment.apartmentNumber))]):_vm._e()]):_vm._e()]}},{key:"item.keys",fn:function(ref){
var item = ref.item;
return _vm._l((item.keys),function(key,index){return _c('span',{key:'a' + index},[_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v(_vm._s(key.code))]),(index < item.keys.length - 1)?_c('span',[_vm._v(" | ")]):_vm._e()])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.itemLoading)?_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('contract', 'create'))?_c('v-icon',{attrs:{"color":_vm.getSignatureColor(item),"title":"Allekirjoitus","small":""},on:{"click":function($event){_vm.showSelectSignatureDialog = true;
              _vm.setToSignatureState(item);}}},[_vm._v("mdi-draw")]):_vm._e(),(_vm.isAuthorized('contract', 'read'))?_c('v-icon',{attrs:{"title":"Lataa sopimus","small":""},on:{"click":function($event){_vm.showDownloadLoader = true;
              _vm.downloadPdf(item);}}},[_vm._v("mdi-file-pdf")]):_vm._e(),(item.signature.status != 'completed' && _vm.isAuthorized('contract', 'edit'))?_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(item.signature.status != 'completed' && _vm.isAuthorized('contract', 'delete'))?_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1):_vm._e(),(item.itemLoading)?_c('v-row',[_c('v-col',[_c('small',[_vm._v("Ladataan sopimusta...")]),_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","rounded":"","height":"6"}})],1)],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei sopimuksia")])]},proxy:true}],null,true)})],1),_c('select-signature',{model:{value:(_vm.showSelectSignatureDialog),callback:function ($$v) {_vm.showSelectSignatureDialog=$$v},expression:"showSelectSignatureDialog"}}),_c('download-loader',{attrs:{"loading":_vm.downloading,"text":"Odota, ladataan sopimusta..."},model:{value:(_vm.showDownloadLoader),callback:function ($$v) {_vm.showDownloadLoader=$$v},expression:"showDownloadLoader"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }