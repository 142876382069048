<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <h2>Vuokralaisen tiedot</h2>
      </v-col>
    </v-row>

    <v-row dense>
      <!-- COL1 -->
      <v-col cols="12" :sm="sm" :md="md" :lg="lg" :xl="xl" class="pr-sm-3">
        <v-row class="mt-1" dense>
          <v-col cols="12">
            <h3>{{ t.isCompany ? "Yritys" : "Henkilö" }}</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper">
              <span class="left">Nimi:</span>
              <strong class="right">{{ t.name }}</strong>
            </p>
            <p v-if="socialOrBusiness" class="info-wrapper">
              <span class="left">{{ t.isCompany ? "Y-tunnus" : "Henkilötunnus" }}:</span>
              <strong class="right">{{ socialOrBusiness }}</strong>
            </p>
            <p v-if="t.phone" class="info-wrapper">
              <span class="left">Puhelinnumero:</span>
              <strong class="right">{{ t.phone }}</strong>
            </p>
            <p v-if="t.email" class="info-wrapper">
              <span class="left">Email:</span>
              <strong class="right link" @click="mailTo([t.email])">{{ t.email }}</strong>
            </p>
            <p v-if="t.address" class="info-wrapper">
              <span class="left">Postiosoite:</span>
              <strong class="right">{{ t.address }}</strong>
            </p>
            <p v-if="t.zipCode" class="info-wrapper">
              <span class="left">Postinumero:</span>
              <strong class="right">{{ t.zipCode }}</strong>
            </p>
            <p v-if="t.city" class="info-wrapper">
              <span class="left">Postitoimipaikka:</span>
              <strong class="right">{{ t.city }}</strong>
            </p>
            <p v-if="t.country" class="info-wrapper">
              <span class="left">Maa:</span>
              <strong class="right">{{ t.country }}</strong>
            </p>
          </v-col>
        </v-row>
      </v-col>

      <!-- COL2 -->
      <v-col cols="12" :sm="sm" :md="md" :lg="lg" :xl="xl" class="pr-sm-3">
        <v-row class="mt-1" dense>
          <v-col cols="12">
            <h3>Muut</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper">
              <span class="left">Vain avaimenhaltija:</span>
              <strong class="right">{{ t.isOnlyKeyHolder ? "Kyllä" : "Ei" }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Status:</span>
              <strong class="right">{{ t.isProspect ? "Potentiaalinen" : "Vuokralainen" }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Alv-rekisterissä:</span>
              <strong class="right">{{ t.vatRegister ? "Kyllä" : "Ei" }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Luottotiedot:</span>
              <strong class="right">{{ creditReport }}</strong>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense>
      <!-- COL 1 -->
      <v-col cols="12" :sm="sm" :md="md" :lg="lg" :xl="xl" class="pr-sm-3">
        <v-row class="mt-sm-1" dense>
          <v-col cols="12">
            <h3>Laskutus</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper">
              <span class="left">Asiakaskohtainen viite käytössä:</span>
              <strong class="right">{{ t.personalRefNumInUse ? "Kyllä" : "Ei" }}</strong>
            </p>
            <p v-if="t.personalRefNumInUse" class="info-wrapper">
              <span class="left">Viite:</span>
              <strong class="right">{{ t.personalRefNum || "Ei muodostettu" }}</strong>
            </p>
            <div v-if="evoice">
              <h4>Verkkolaskutiedot</h4>
              <p class="info-wrapper">
                <span class="left">Operaattori:</span>
                <strong class="right">{{ evoice.operator }}</strong>
              </p>
              <p class="info-wrapper">
                <span class="left">Tunnus:</span>
                <strong class="right">{{ evoice.id }}</strong>
              </p>
              <p class="info-wrapper">
                <span class="left">Laskuosoite:</span>
                <strong class="right">{{ evoice.address }}</strong>
              </p>
            </div>
            <h4>Vakuudet</h4>
            <p class="info-wrapper">
              <span class="left">{{
                currentDepositAmount === null ? "Rahavakuuksia" : "Vakuuksia palauttamatta"
              }}</span>
              <strong class="right">{{
                currentDepositAmount !== null ? formatCurrency(currentDepositAmount) : "Ei"
              }}</strong>
            </p>
          </v-col>
        </v-row>
      </v-col>

      <!-- COL 2 -->
      <v-col v-if="t.comments" cols="12" :sm="sm" :md="md" :lg="lg" :xl="xl" class="pr-sm-3">
        <v-row class="mt-sm-1" dense>
          <v-col cols="12">
            <h3>Lisätiedot</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper-noborder" style="white-space: pre-line">
              {{ t.comments }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "@/mixins/mixins";
import { mapState, mapActions } from "vuex";
export default {
  props: ["sm", "md", "lg", "xl"],

  mixins: [mixins],

  computed: {
    ...mapState("deposit", ["tenantDeposits"]),

    t() {
      return this.$store.state.tenant.currentTenant;
    },

    socialOrBusiness() {
      if (this.t.isCompany) {
        return this.t.businessId || "Ei määritelty";
      } else {
        return this.t.social || "Ei määritelty";
      }
    },

    creditReport() {
      let string;
      switch (this.t.creditReport) {
        case "notChecked":
          string = "Ei tarkistettu";
          break;
        case "passed":
          string = "Luottotiedot ok";
          break;
        case "paymentFailure":
          string = "Maksuhäiriö";
          break;
      }

      return string;
    },

    currentDepositAmount() {
      let received = 0;
      let released = 0;

      if (this.tenantDeposits.length > 0) {
        this.tenantDeposits.forEach((deposit) => {
          received += deposit.received.reduce((sum, cur) => {
            sum += cur.amount;
            return sum;
          }, 0);

          released += deposit.released.reduce((sum, cur) => {
            sum += cur.amount;
            return sum;
          }, 0);
        });

        return parseFloat((received - released).toFixed(2));
      } else {
        return null;
      }
    },

    evoice() {
      if (this.t?.evoice?.evoiceAddress) {
        return {
          address: this.t.evoice.evoiceAddress,
          id: this.t.evoice.evoiceId || null,
          operator: this.t.evoice.evoiceOperator || null,
        };
      } else return null;
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.t._id) this.getDeposits(this.t);
    });
  },

  watch: {
    t(tenant) {
      this.getDeposits(tenant);
    },
  },

  methods: {
    ...mapActions("deposit", ["getTenantDeposits"]),

    getDeposits(tenant) {
      this.getTenantDeposits(tenant._id);
    },
  },
};
</script>

<style scoped>
.info-wrapper {
  display: flex;
  border-bottom: 1px solid #d4d4d4;
}

.info-wrapper-noborder {
  display: flex;
}

.left {
  width: 140px;
}

.right {
  flex: 1;
  text-align: right;
}

.link {
  color: var(--v-info-base);
  cursor: pointer;
}
</style>
