<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Vuokralainen / avaimenhaltija</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <v-card class="mt-2">
      <v-card-title>
        <p>{{ currentTenant.name }} <span v-if="!currentTenant.active">(poistettu)</span></p>
        <v-spacer></v-spacer>
        <router-link v-if="!currentTenant.isOnlyKeyHolder" :to="`/tenant/edit/${$route.params.id}`">
          <v-btn color="primary">Muokkaa</v-btn>
        </router-link>
      </v-card-title>

      <v-container fluid>
        <v-row dense>
          <v-col>
            <!-- TABS -->
            <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
              <v-tabs-slider color="parimary"></v-tabs-slider>

              <v-tab v-for="item in tabs" :key="item.text">
                <div>{{ item }}</div>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <!-- Components -->
        <tenant-info v-if="tab == 0" sm="12" md="5" lg="5" xl="3"></tenant-info>
        <tenant-notes v-if="tab == 1"></tenant-notes>
        <tenant-rental-contracts v-if="tab == 2"></tenant-rental-contracts>
        <tenant-invoices v-if="tab == 3"></tenant-invoices>
        <tenant-contracts v-if="tab == 4"></tenant-contracts>
        <tenant-keys v-if="tab == 5"></tenant-keys>
        <tenant-reports v-if="tab == 6"></tenant-reports>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import TenantInfo from "../../components/Tenant/TenantInfo";
import TenantKeys from "../../components/Tenant/TenantKeys";
import TenantContracts from "../../components/Tenant/TenantContracts";
import TenantReports from "../../components/Tenant/TenantReports";
import TenantRentalContracts from "../../components/Tenant/TenantRentalContracts";
import TenantInvoices from "../../components/Tenant/TenantInvoices";
import TenantNotes from "../../components/Tenant/TenantNotes";
import { mapActions, mapState } from "vuex";

export default {
  title: "Vuokralaisen hallinta",

  components: {
    TenantInfo,
    TenantKeys,
    TenantContracts,
    TenantReports,
    TenantRentalContracts,
    TenantInvoices,
    TenantNotes,
  },

  mixins: [mixins],

  data() {
    return {
      search: "",
      tab: 0,
      tabs: [
        "Yhteenveto",
        "Muistiinpanot",
        "Vuokrasopimukset",
        "Laskut",
        "Muut sopimukset",
        "Avaimet",
        "Raportit",
      ],
    };
  },

  computed: {
    ...mapState("tenant", ["currentTenant"]),
  },

  methods: {
    ...mapActions("tenant", ["getOneTenant"]),
  },

  created() {
    this.getOneTenant("/api/v1/tenant/getOne/" + this.$route.params.id);
  },

  mounted() {
    if (this.$route.query.tab) {
      this.tab = Number(this.$route.query.tab);
    }
  },

  watch: {
    "$route.params.id": {
      handler: function () {
        this.getOneTenant("/api/v1/tenant/getOne/" + this.$route.params.id);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
